export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startpagina"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ons"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "affordable-quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assemblage op maat"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom"])},
  "slider-about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabelassemblage, kabelbomen, besturing-en schakelpanelen."])},
  "slider-about-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "more-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meer details"])},
  "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your challenge our solution"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorig"])},
  "footer": {
    "site-links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site links"])},
    "request-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online formulier aanvragen"])},
    "get-in-touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op met ons"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabelassemblage en panelenbouw"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacyverklaringen"])},
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "cookie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie"])}
  },
  "impressum-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impresszum"])},
    "business": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vállalkozás adatai"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégnév"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
      "post-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postai cím"])},
      "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adószám"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégjegyzékszám"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
      "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankszámlaszám"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
    },
    "domain": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tárhelyszolgáltató adatai"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégnév"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])}
    },
    "bank": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankszámlaszámok"])}
    }
  },
  "about-page": {
    "section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer ons kennen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabelassemblage en panelenbouw"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinds 2011 heeft Cable Solutions een prominente positie verworven in de elektro- en elektronicasector als toonaangevend assemblagebedrijf voor kabels, draden, schakel- en besturingskasten en panelen. Met Nederlands management en een productielocatie in Hongarije bieden wij hoogwaardige oplossingen voor allerlei elektrische assemblages."])},
    "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sinds 2011"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We kunnen beloven dat als U ons de kans geeft, u zal er geen spijt van hebben"])},
    "checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij bieden oplossingen op verschillende gebieden"])},
    "check-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbewerking en assemblage van elektronische componenten en producten."])},
    "check-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkoop van materialen en/of gebruik van door de klant aangeleverde materialen voor onze elektronische assemblage services."])},
    "check-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze deskundige engineeringsdiensten zijn beschikbaar voor alle producten die we produceren, met een focus op elektronische componenten"])},
    "check-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij zijn ook gespecialiseerd in het verbeteren van bestaande producten, zowel op technisch als financieel gebied, om daarmee de prestaties en kostenefficiëntie te maximaliseren."])},
    "view-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees meer over Cable Solutions"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact met ons op"])}
  },
  "about-page-2": {
    "page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer ons kennen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabelassemblage en panelenbouw "])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cable Solutions  is sinds 2011 aanwezig op de elektro- en elektronicamarkt als assemblagebedrijf voor kabel, draad en schakel- en, besturingspanelen, met Nederlandse management en productielocatie in Hongarije."])},
    "paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdens ons ruim 13 jarig bestaan is de tevredenheid van onze klanten voor ons het allerbelangrijkste, velen van hun vertrouwen ons al vanaf de allereerste levering. Wij leveren aan allerlei tevreden klanten binnen Europa."])},
    "paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze medewerkers kunnen zorgvuldig en nauwkeurig multitasken en beschikken over een meerdere jaren ervaring, waardoor kwaliteit kan worden gegarandeerd"])},
    "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarom voor ons kiezen:/ waarom samenwerken"])},
    "answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scherpe prijzen"])},
    "answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nauwkeurig"])},
    "answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands management"])},
    "answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongaarse productie"])},
    "answer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-producten en merken ( Phoenix Contact, Weidmuller, Finder, Schneider…)"])},
    "answer-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tijdige snelle levering "])},
    "answer-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gedreven"])},
    "answer-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flexibiel"])},
    "answer-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creatief"])},
    "answer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betrouwbaar"])},
    "answer-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["communicatie kan in het Nederlands, Engels, Duits en Hongaars"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top kwaliteit"])},
    "quality-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij produceren in onze eigen productiefaciliteit in Hongarije met Nederlands management, wij garanderen hoog rendement, uitstekende kwaliteit en hoge service."])},
    "flexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelle bezorging"])},
    "flexibility-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door de efficiënte Hongaarse productie en een goede afstemming van de logistiek zult u ook geen verschil merken bij de levering tot onze concurrenten"])},
    "high-efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuïteit"])},
    "high-efficiency-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door onze jarenlange ervaring zijn in staat alle voorkomende montages tot een goed resultaat te brengen, en daarmee kunnen wij u een langdurige samenwerking garanderen"])}
  },
  "team": {
    "our-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team"])},
    "experience-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXPERIENCE TEAM"])},
    "meet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet Our Awesome Team Member"])}
  },
  "statistics-page": {
    "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrische"])},
    "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en elektronica "])},
    "title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vervaardigend"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["producten"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klanten"])},
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ervaring"])},
    "projects-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantspecifieke"])},
    "clients-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevreden"])},
    "experience-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 jaar"])}
  },
  "products-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij bieden topproductie vanuit elke hoek"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze montage van draad, kabel schakel- en besturingspanelen staat bekend om zijn zorgvuldige precisie. Met jarenlange ervaring hebben we sterke partnerships opgebouwd met toonaangevende fabrikanten, wat ons in staat stelt om hoogwaardige producten te leveren."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk meer producten"])},
    "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto’s"])}
  },
  "product-detail-page": {
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productinformatie"])},
    "shortdescription:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit."])},
    "related-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerelateerde producten"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlandse tekst:Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."])}
  },
  "contact-us-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op!"])},
    "head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdkantoor"])},
    "branch-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productie locatie"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "call-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bel ons"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "head_office_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
    "head_office_postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4751 GX"])},
    "head_office_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud Gastel"])},
    "head_office_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Potterestraat 1 B"])},
    "branch_office_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongarije"])},
    "branch_office_postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6060"])},
    "branch_office_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiszakécske"])},
    "branch_office_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dózsa Telep 83."])},
    "head_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Potterestraat 1 B 4751 GX Oud Gastel, Nederland"])},
    "branch_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dózsa Telep 83. 6060 Tiszakécske, Hongarije"])}
  },
  "contact-form": {
    "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact "])},
    "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulier"])},
    "subtitle-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt U dat wij contact met U opnemen vul dan minimaal even uw mailadres in of neem gerust contact met ons op via"])},
    "subtitle-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "subtitle-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderwerp"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu verzenden"])}
  }
}