export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdőlap"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rólunk"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termékeink"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat"])},
  "affordable-quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyedi összeszerelés"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözöljük"])},
  "slider-about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kábelkonfekcionálás, kábelkorbácsok, vezérlő- és kapcsolószekrények"])},
  "slider-about-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "more-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismerjen meg minket"])},
  "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your challenge our solution"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előző"])},
  "footer": {
    "site-links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site links"])},
    "request-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online kapcsolatfelvétel"])},
    "get-in-touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegye fel velünk a kapcsolatot"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kábelkonfekcionálás, kábelkorbácsok, vezérlő- és kapcsolószekrények"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatvédelmi tájékoztató"])},
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impresszum"])}
  },
  "impressum-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impresszum"])},
    "business": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vállalkozás adatai"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégnév"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
      "post-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postai cím"])},
      "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adószám"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégjegyzékszám"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
      "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankszámlaszám"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
    },
    "domain": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tárhelyszolgáltató adatai"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégnév"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])}
    },
    "bank": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankszámlaszámok"])}
    }
  },
  "about-page": {
    "section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismerjen meg minket"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kábelszerelvények és elektronikai dobozok gyártása"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cable Solutions 2011 óta van jelen az elektronikai termékek piacán, mint kábel-, vezeték-, és vezérlőszekrények egyik vezető összeszerelő vállalata, holland ügyvezetéssel, magyarországi gyártó telephellyel, kiváló minőségű megoldásokat kínálunk mindenféle elektromos szerelvényhez"])},
    "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2011 óta"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megígérhetjük, ha esélyt ad nekünk, nem fogja megbánni"])},
    "checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számos területen kínálunk megoldást"])},
    "check-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elektronikai alkatrészek és termékek, összeszerelése, előmunkálása"])},
    "check-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alapanyagok beszerzése és/vagy a megrendelő által szállított anyagok felhasználása"])},
    "check-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szakértői mérnöki szolgáltatásaink minden általunk gyártott termékhez rendelkezésre állnak, különös tekintettel az elektronikai alkatrészekre"])},
    "check-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szakterületünk a meglévő termékek műszaki és pénzügyi fejlesztése is a teljesítmény és költséghatékonyság maximalizálása érdekében"])},
    "view-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudjon meg többet"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegye fel velünk a kapcsolatot"])}
  },
  "about-page-2": {
    "page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismerjen meg minket"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kábelszerelvények és elektronikai dobozok gyártása"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cable Solutions 2011 óta van jelen az elektronikai termékek piacán, mint kábel-, vezeték-, és vezérlőszekrényeket összeszerelő cég, holland ügyvezetéssel, magyarországi gyártó telephellyel."])},
    "paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Több mint 13 éves fennállásunk óta ügyfeleink elégedettsége a legfontosabb számunkra, sokan közülük már a kezdetektől bíznak bennünk. Európa számos országából látnak el minket megrendelésekkel."])},
    "paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkavállalóink precíz, gondos munkával sokféle feladat megoldására képesek, sok éves tapasztalattal rendelkeznek, igy garantálva a minőséget. Cégünknél minden anyag és szerszám adott a termékek gyártásához és teszteléséhez."])},
    "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miért válasszon minket"])},
    "answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["precíz munkavégzés"])},
    "answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["holland vezetés"])},
    "answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["magyar gyártás"])},
    "answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kiváló  minőségű alapanyagok( Phoenix Contact, Weidmuller, Finder, Schneider...)"])},
    "answer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pontos és gyors szállítás"])},
    "answer-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eltökéltség"])},
    "answer-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rugalmas"])},
    "answer-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kreatív"])},
    "answer-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["megbízható"])},
    "answer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommunikáció holland, angol, magyar és német nyelven"])},
    "answer-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulajdonos"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiváló minőség"])},
    "quality-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindent saját magyarországi üzemünkben gyártunk, holland vezetéssel, garantáljuk a magas hatékonyságot és minőséget. "])},
    "flexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyors szállítás"])},
    "flexibility-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatékony gyártás és logisztika megfelelő baállításával a szállításban sem lesz különbség legtöbb versenytársunkkal szemben."])},
    "high-efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapasztalat"])},
    "high-efficiency-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Több éves tapasztalatunknak köszönhetően képesek vagyunk minden összeszerelést lebonyolítani, hogy jó eredményt és ezáltal hosszú távú együttműködést hozzunk létre."])}
  },
  "team": {
    "our-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team"])},
    "experience-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXPERIENCE TEAM"])},
    "meet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet Our Awesome Team Member"])}
  },
  "statistics-page": {
    "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kábelszerelvények"])},
    "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["és elektronikai dobozok"])},
    "title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gyártása"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termékek"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ügyfelek"])},
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tapasztalat "])},
    "projects-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vevőspecifikus"])},
    "clients-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elégedett"])},
    "experience-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 év"])}
  },
  "products-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termékeink"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiváló minőségű termékeket gyártunk"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az általunk gyártott termékek között megtalálhatók a legkülönbözőbb kábelkorbácsok, szalag-, vezérlő-, és spirálkábelek, valamint nagy tapasztalattal rendelkezünk a vezérlő-, és kapcsoló szekrények építésében is. Erős partneri kapcsolatokat építettünk ki vezető gyártókkal, ami lehetővé teszi számunkra a kiváló minőségű termékek kiszállítását."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További termékeink"])},
    "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotók"])}
  },
  "product-detail-page": {
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termék információk"])},
    "related-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolódó termékek"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar szöveg: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."])}
  },
  "contact-us-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lépjen kapcsolatba velünk!"])},
    "head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Központi irodánk"])},
    "branch-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephelyünk"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "call-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hívjon minket"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "head_office_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hollandia"])},
    "head_office_postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4751 GX"])},
    "head_office_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud Gastel"])},
    "head_office_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Potterestraat 1 B"])},
    "branch_office_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyarország"])},
    "branch_office_postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6060"])},
    "branch_office_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiszakécske"])},
    "branch_office_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dózsa Telep 83."])},
    "head_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Potterestraat 1 B 4751 GX Oud Gastel, Hollandia"])},
    "branch_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dózsa Telep 83. 6060 Tiszakécske, Magyarország"])}
  },
  "contact-form": {
    "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írjon "])},
    "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nekünk"])},
    "subtitle-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szeretné, hogy felvegyük Önnel a kapcsolatot? Töltse ki a az email címét vagy vegye fel velünk a kapcsolatot"])},
    "subtitle-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vagy"])},
    "subtitle-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook-on"])},
    "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn-en"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tárgy"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzenet"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküld"])}
  }
}