export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "affordable-quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom assembly"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "slider-about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assembly of cable harnesses, ribbon, control and spiral cables, control and switch cabinets/boxes"])},
  "more-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More details"])},
  "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your challenge our solution"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pervious"])},
  "footer": {
    "site-links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site links"])},
    "request-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Online Form"])},
    "get-in-touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch with us"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cable and cabinet assembly"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Statements"])},
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "cookie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie"])}
  },
  "impressum-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impresszum"])},
    "business": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vállalkozás adatai"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégnév"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
      "post-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postai cím"])},
      "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adószám"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégjegyzékszám"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
      "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankszámlaszám"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
    },
    "domain": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tárhelyszolgáltató adatai"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégnév"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])}
    },
    "bank": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankszámlaszámok"])}
    }
  },
  "about-page": {
    "section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who we are"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cable and cabinet assembly"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cable Solutions has been present on the electronic products market since 2011 as a company assembling cable, wire and electronic control boxes, with Dutch management and production site in Hungary."])},
    "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["since 2011"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can promise if you give us the chance you never regret it"])},
    "checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer solutions in several areas"])},
    "check-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assembly and pre-processing of electronic components and products"])},
    "check-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["purchase of materials and/or use of materials supplied by the customer"])},
    "check-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["engineering for all products we make"])},
    "check-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["improvement of existing products on a technical and possibly financial level"])},
    "view-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "about-page-2": {
    "page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who we are"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cable and cabinet assembly"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cable Solutions has been present on the electronic products market since 2011 as a company assembling cable, wire and electronic control boxes, with Dutch management and production site in Hungary"])},
    "paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During our more than 13 years of existence, the satisfaction of our customers is the most important  to us, many of them trusted us from the very first delivery. We deliver to various satisfied customers within Europe."])},
    "paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our employees are able to solve a wide variety of tasks with precise and careful work, and have fine motor skills so ensuring quality. "])},
    "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why cooperate:"])},
    "answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accurate"])},
    "answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch management, Hungarian production"])},
    "answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-products and brands (Phoenix Contact, Weidmuller, Finder, Schneider…)"])},
    "answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on time and fast delivery"])},
    "answer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on-time deliveries"])},
    "answer-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motivated"])},
    "answer-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flexible"])},
    "answer-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creative"])},
    "answer-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trustworthy"])},
    "answer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["communication is possible in Dutch, English, German and Hungarian"])},
    "answer-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent quality"])},
    "quality-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We manufacture in our own production facility in Hungary with Dutch management we guarantee a high efficiency and excellent quality."])},
    "flexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast delivery"])},
    "flexibility-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The efficient production and proper adjustment of the logistics you will also notice no difference in the delivery of most of our competitors."])},
    "high-efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "high-efficiency-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through our years of experience we are able to manage all common assemblies to bring a good result and thus long-term collaboration to cover."])}
  },
  "team": {
    "our-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team"])},
    "experience-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXPERIENCE TEAM"])},
    "meet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet Our Awesome Team Member"])}
  },
  "statistics-page": {
    "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cable"])},
    "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and cabinet "])},
    "title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assembly"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["satisfied customer"])},
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["experience"])},
    "projects-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hundreds of"])},
    "clients-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many"])},
    "experience-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 13 years of"])}
  },
  "products-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Give Top Production From Every Angle"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All switch-, and paneling boxes are assembled with utmost care. We have been working with  high-quality material manufacturers for years."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more products"])},
    "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto’s"])}
  },
  "product-detail-page": {
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product information"])},
    "shortdescription:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English text: Lorem ipsum dolor sit amet, consectetur adipiscing elit."])},
    "related-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related products"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English text: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."])}
  },
  "contact-us-page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get In Touch!"])},
    "head-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head Office"])},
    "branch-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production site"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "call-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Us"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "head_office_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
    "head_office_postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4751 GX"])},
    "head_office_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud Gastel"])},
    "head_office_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Potterestraat 1 B"])},
    "branch_office_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
    "branch_office_postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6060"])},
    "branch_office_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiszakécske"])},
    "branch_office_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dózsa Telep 83."])},
    "head_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Potterestraat 1 B 4751 GX Oud Gastel, Netherlands"])},
    "branch_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dózsa Telep 83. 6060 Tiszakécske, Hungary"])}
  },
  "contact-form": {
    "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact "])},
    "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
    "subtitle-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feel free to contact us through"])},
    "subtitle-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "subtitle-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if you prefer."])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send now!"])}
  }
}