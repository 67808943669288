export const contact = {
   head_office:{
    email: "e.baselier@cablesolutions.eu",
    phone: "+31 646 445 893",
    address: "De Potterestraat 1 B 4751 GX Oud Gastel, Hollandia"
   },
   branch_office:{
    email: "administration@cablesolutions.eu",
    phone: "+31 621 955 470",
    address: "Cable Solutions Kft. Dózsa Telep 83. 6060 Tiszakécske"
   }
   
  };