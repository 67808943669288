import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductView from '../views/ProductView.vue'
import AboutView from '../views/AboutView.vue'
import ImpressumView from '../views/ImpressumView.vue'
const routes = [
  
  {
    path: '/:pathMatch(.*)*',
    name: HomeView,
    meta: {
      title: "HomeView",
      
    },
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: '/',
    name: HomeView,
    meta: {
      title: "HomeView",
      
    },
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/product/:id",
    name: ProductView,
    meta: {
      title: "ProductView",
      
    },
    component: () => import("../views/ProductView.vue"),
 
  },
  
  {
    path: "/about",
    name: AboutView,
    meta: {
      title: "AboutView",
      
    },
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/impressum",
    name: ImpressumView,
    meta: {
      title: "ImpressumView",
      
    },
    component: () => import("../views/ImpressumView.vue"),
    props: true 
  },
 
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
  },
})


export default router
