<template>
   <div class="site-main">
      <ContactInfo id="info"/>
   </div>
</template>
<script>
import ContactInfo from './ContactInfo.vue'

export default {
  name: 'ContactUs',
  components: {
    ContactInfo,
  }
}
</script>