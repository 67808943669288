export const data = {
  
   items: [
        { 
          id:1,
          title_en: "Cable assemby",
          title_hu: "Kábelszerelés",
          title_nl: "Kabel- en draadassemblage",
          shortdescription_en: "Outsource de productie van uw kabels aan ons voor een snelle, hoogwaardige en kostenefficiënte oplossing.",
          shortdescription_hu: "Bízza ránk kábelei gyártását és mi gyors, minőségi és megfizethető megoldást kínálunk. ",
          shortdescription_nl: "Besteed de productie van uw kabels aan ons uit en wij zorgen voor een snelle, kwalitatief hoge en voordelige oplossing.",
          pic: require("../../assets/img/products/cable/01.jpg"),
          banner: require("../../assets/img/products/cable/banner.jpg"),
          pics:[ {
            pic: require("../../assets/img/products/cable/02.jpg")
          },
          {
            pic: require("../../assets/img/products/cable/03.jpg")
          },
          {
            pic: require("../../assets/img/products/cable/04.jpg")
          },
          {
            pic: require("../../assets/img/products/cable/05.jpg")
          },
          {
            pic: require("../../assets/img/products/cable/06.jpg")
          },
          {
            pic: require("../../assets/img/products/cable/07.jpg")
          },
        ],
        },
        { 
          id:2,
          title_en: "Cabinets",
          title_hu: "Elektronikai dobozok",
          title_nl: "Kasten en panelenbouw",
          shortdescription_nl: "Onze zorgvuldige montage van draad, kabel schakel- en besturingspanelen, samen met hoogwaardige componenten van betrouwbare leveranciers, garanderen aantrekkelijke prijzen en een hoge leverbetrouwbaarheid.",
          shortdescription_hu: "Minden kapcsoló- és elosztóberendezést a legnagyobb gondossággal állítunk össze. Évek óta dolgozunk kiváló minőségű alapanyaggyártókkal, ezzel biztosítva a vonzó árakat és szállítási megbízhatóságot. ",
          shortdescription_en: "All switch-, and paneling boxes are assembled with utmost care. We have been working with  high-quality material manufacturers for years",
          pic:require("../../assets/img/products/box/01.jpg"),
          banner: require("../../assets/img/products/box/banner.jpg"),
          pics:[ {
            pic: require("../../assets/img/products/box/02.jpg"),
          },
          {
            pic: require("../../assets/img/products/box/03.jpg")
          },
          {
            pic: require("../../assets/img/products/box/04.jpg"),
          },
          {
            pic: require("../../assets/img/products/box/05.jpg")
          },
          {
            pic: require("../../assets/img/products/box/06.jpg"),
          },
          {
            pic: require("../../assets/img/products/box/07.jpg")
          },
          {
            pic: require("../../assets/img/products/box/08.jpg")
          },
        ],
       
        },
        { 
          id:3,
          title_en: "Wiring harnesses",
          title_hu: "Kábelkorbácsok",
          title_nl: "Kabelbomen",
          shortdescription_nl: "Wij verzorgen de assemblage van kabels, draden en componenten in kleine tot grote batches volgens uw specificaties en behoeften. ",
          shortdescription_hu: "Vállaljuk a megrendelő által biztosított tervek vagy mintadarabok alapján különféle alkatrészek és részegységek összeszerelését kis és közepes tételben. ",
          shortdescription_en: "We undertake the assembly of various components and subassemblies in small and medium batches, based on plans or sample  provided by the customer.",
          pic:require("../../assets/img/products/wiring-harnesses/01.jpg"),
          banner: require("../../assets/img/products/wiring-harnesses/banner.jpg"),
          pics:[ 
          {
            pic: require("../../assets/img/products/wiring-harnesses/02.jpg")
          },
          {
            pic: require("../../assets/img/products/wiring-harnesses/03.jpg"),
          },
          {
            pic: require("../../assets/img/products/wiring-harnesses/04.jpg"),
          },
        ],
        },
        { 
          id:4,
          title_en: "Mechanical assembly",
          title_hu: "Mechanikai összeszerelés",
          title_nl: "Mechanische assemblage",
          shortdescription_nl: "Laat ons uw onderdelen en subassemblages assembleren volgens uw individuele behoeften. Wij kunnen alles regelen, inclusief inkoop, of u kunt zelf materialen aanleveren. Geen enkel probleem!.",
          shortdescription_hu: "Vállaljuk egyedi igények/megrendelő által biztosított tervek alapján különféle alkatrészek és részegységek összeszerelését kis és közepes tételben, igény szerint anyagbeszerzéssel is",
          shortdescription_en: "We undertake the assembly of various components and subassemblies in small and medium batches, based on individual needs/plans provided by the customer. If necessary, with the purchase of materials",
          pic:require("../../assets/img/products/mechanical/01.jpg"),
          banner: require("../../assets/img/products/mechanical/banner.jpg"),
          pics:[ {
            pic: require("../../assets/img/products/mechanical/02.jpg"),
          },
          {
            pic: require("../../assets/img/products/mechanical/03.jpg")
          },
          {
            pic: require("../../assets/img/products/mechanical/04.jpg"),
          },
          {
            pic: require("../../assets/img/products/mechanical/05.jpg"),
          },
          {
            pic: require("../../assets/img/products/mechanical/06.jpg"),
          },
          {
            pic: require("../../assets/img/products/mechanical/07.jpg"),
          }
        ],
        },
      ]
}

